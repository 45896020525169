import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Blogs from '../components/Blogs';
import { graphql } from 'gatsby';

export default ({data}) => {
    const { allStrapiBlogs:{ nodes: blogs } } = data;
    return (
        <Layout>
            <SEO title="Blog" description="Blog articles"/>
            <div className="section-container top" id="blogs">
                <Blogs blogs={blogs} title="Blog"></Blogs>
            </div>
        </Layout>
    )
}

export const query = graphql`
{
    allStrapiBlogs(sort: {order: DESC, fields: date}, limit: 4) {
      nodes {
        strapiId
        title
        description
        slug
        thumbnail {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        date(formatString: "MMMM Do, YYYY")
        category
      }
    }
  }
`